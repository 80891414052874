import React from "react";

import styles from "./styles.module.scss";

import Copy from "../../../components/shared/Copy/Copy";
import Avatar from "../../../components/shared/Avatar/Avatar";
import parsePhoneNumber from "libphonenumber-js";
import useGetOrderTypeData from "@/utils/hooks/stateSwitchers/useGetOrderTypeData";
import useGetRemainingTime from "./useGetRemainingTime";
import { ReactComponent as FileIcon } from "../../../assets/icons/file-05.svg";
import { motion } from "framer-motion";
import { IOrderItem } from "../../../store/orders/orders.types";
import { useGetBanksListQuery } from "../../../store/payoutLimits/payoutLimits.api";
import { parseDate } from "../../../utils/helpers/parseDate";
import { transformCurrencyValue } from "../../../utils/helpers/transformCurrencyValue";
import { paymentColorFullIcons } from "../../../utils/constants/paymentColorFulIcons";
import { formatCardNumber } from "../../../utils/helpers/formatCardNumber";
import { Img } from "react-image";
import { Skeleton } from "@mui/material";
import { ReactComponent as SbpIcon } from "../../../assets/icons/sbp.svg";
import { useTranslation } from "react-i18next";

interface IOrderItemProps {
  isOpen: boolean;
  onOpenClick: () => void;
  data: IOrderItem;
}

export default function OrderItem({
  isOpen,
  onOpenClick,
  data,
}: IOrderItemProps) {
  const getTypeData = useGetOrderTypeData();
  const getRemainingTime = useGetRemainingTime();
  const { t } = useTranslation(undefined, { keyPrefix: "ordersPage" });
  const { data: banksData, isLoading: isBanksLoading } = useGetBanksListQuery({
    regionId: 1,
  });
  let bank;
  if (banksData?.data) {
    bank = banksData.data.find((i) => i.alias === data.bank_name);
  }

  function onItemClick() {
    if (isOpen) {
      return;
    }
    onOpenClick();
  }

  const typeData = getTypeData(data.state);

  return (
    <div className={styles.item} onClick={onItemClick}>
      <div className={styles.itemTop}>
        <div className={styles.itemStatus}>
          <Avatar type={typeData.type as "accent"} mode="square">
            {typeData.icon}
          </Avatar>
          <div className={styles.textBlock}>
            <div className={styles.statusTitle}>{typeData.title}</div>
            <div className={styles.statusDescription}>
              {t("statusDescription")}: {parseDate(data.created_at, "numeric")}
            </div>
          </div>
        </div>
        <div className={styles.amount}>
          {data.amount && transformCurrencyValue(+data.amount)}{" "}
          {data?.currency?.toUpperCase()}
        </div>
      </div>
      {data.state === "paid" && data.expire_at && (
        <p className={styles.remainingTime}>
          {t("remainingTime", {
            remainingTime: getRemainingTime(new Date(data.expire_at)),
          })}
        </p>
      )}
      <div className={styles.info}>
        <div className={styles.bottom}>
          <div className={styles.requisites}>
            <div className={styles.bank}>
              <Img
                className={styles.bankLogo}
                src={bank?.logo || ""}
                alt="Bank logo"
                loader={<Skeleton width={20} height={20} variant="rounded" />}
              />
              <span>
                {isBanksLoading ? <Skeleton width={42} /> : bank?.name}
              </span>
            </div>
            <div className={styles.paySystem}>
              {data.sbp ? (
                <SbpIcon />
              ) : (
                paymentColorFullIcons[data.card_brand as "visa"] || ""
              )}
              <p>
                {data.sbp
                  ? parsePhoneNumber(data.requisites)?.formatNational()
                  : formatCardNumber(data.requisites)}
              </p>
            </div>
          </div>
        </div>
        {
          <motion.div
            initial={{ maxHeight: 0 }}
            animate={{ maxHeight: isOpen ? 200 : 0 }}
            className={styles.dropContent}
          >
            <div className={styles.block}>
              <div className={styles.blockTitle}>{t("orderID")}</div>
              <div className={styles.idContainer}>
                <span>{data.uuid}</span>{" "}
                <div className={styles.copy}>
                  <Copy value={data.uuid} />
                </div>
              </div>
            </div>

            {data.proof && (
              <div className={styles.block}>
                <div className={styles.blockTitle}>{t("receiptTitle")}</div>
                <div className={styles.receipt}>
                  <div className={styles.receiptContainer}>
                    <FileIcon />
                    <span>{data.proof.filename}</span>
                  </div>{" "}
                  <a href={data.proof.url}>
                    {t("openFile")} -{">"}
                  </a>
                </div>
              </div>
            )}
          </motion.div>
        }
      </div>
    </div>
  );
}
