const orderPageText = {
  orderPage: {
    timer: {
      description: "Time to pay the order:",
      timeIsOver: "Time is over",
      min: "min",
      sec: "sec",
    },
    modalEmptyNext: {
      title: "No active orders",
      description:
        "The system was unable to find an order that matches your account parameters.",
      params: "Account parameters",
      close: "Close",
    },
    modalCancel: {
      title: "Cancel the order",
      subTitle: "Select the reason for canceling the order",
      blocked: {
        title: "Recipient's card is blocked",
      },
      troubles: {
        title: "Payment problems",
        notice:
          "The current order will be transferred to another executor, and your token will be disabled",
      },
      submit: "Cancel the order",
      errors: {
        verification: "Cannot cancel an order that is being verified",
      },
    },
    modalMakePaid: {
      errors: {
        id: {
          title: "Error",
          description: "ID not found",
        },
      },
      title: "Didn't receive a payment receipt?",
      description:
        "Click the <b>Upload later</b> button, and MassMo will consider your payment as temporary.",
      warning:
        "In case of confirmation, you must attach a receipt within 12 hours {{date}}, otherwise we will cancel the payment",
      submit: "Upload later",
    },
    reservesBlock: {
      collapse: "Collapse",
      expand: "All reserves",
    },
    reservesOver: {
      title: "Reserves are exhausted",
      description:
        "You cannot pay for the order, <br /> because you do not have enough reserves",
      button: "Change reserves",
    },
    notFound: {
      title: "You do not have an active order",
      description: 'Click "Get an order" <br /> to request a new order',
      nextTranslates: {
        keys: {
          base: "Error",
        },
        values: {
          suspended: "Token is suspended",
        },
      },
      button: "Get an order",
    },
    error: {
      title: "Error",
    },
    tokenDisabled: {
      title: "Token is disabled",
      description: "You do not receive orders",
      button: "Account management",
    },
    processing: {
      title: "Order is awaiting payment",
    },
    paid: {
      title: "Order is awaiting receipt upload",
      description: "Upload the receipt by",
    },
    verification: {
      title: "Verifying your payment",
      description: "The system is scanning the payment receipt",
    },
    completed: {
      title: "Order is paid",
      description: "Payment has been verified",
    },
    expired: {
      title: "Order was not paid",
      description: "Payment time has expired",
    },
    verification_failed: {
      title: "Payment did not pass verification",
      description:
        "Check the correctness of the receipt <br /> and recipient's details",
    },
    manual_verification: {
      title: "Manual verification by administration",
      description: "Payment is being verified",
    },
    canceled: {
      title: "Order was canceled",
      description: "You canceled this order",
    },
    default: {
      title: "Unknown order status",
      description: "Error, unable to determine order status",
    },
    modalSelectBank: {
      title: "Bank for payment",
      description: "Select a bank for payment",
      search: {
        placeholder: "Search",
      },
    },
    processingTexts: {
      amount: "Transfer exactly:",
      requisites: "Strictly according to the requisites in the specified bank:",
      bankChose: "After payment, select the bank from which you paid:",
      receipt: "After payment, upload the payment receipt:",
    },
    receipt: {
      placeholder: "Click to upload payment receipt",
    },
    buttonMakePaid: "Paid, but did not receive receipt?",
    submit: "Submit receipt",
    nextOrder: {
      translates: {
        keys: {
          base: "Error",
        },
        values: {
          suspended: "Token is suspended",
        },
      },
      button: "Get an order",
    },
    detailsBlock: {
      title: "Additional Information",
      createdAt: "Creation Date:",
      uuid: "Payment ID:",
    },
    cancelBlock: {
      title: "Payment Issues?",
      cancel: "Cancel Payment",
    },
    amountBlock: {
      title: "Payment Amount",
    },
    bankChose: {
      title: "Which bank did you use to pay for the order?",
      empty: "Not selected",
      chose: "Select",
    },
    requisiteBlock: {
      empty: "No bank information available",
    },
    verificationFailedBlock: {
      confirm: "Send for manual verification by administration",
      modal: {
        title: "Send for verification?",
        description1:
          "Are you sure you want to send this order for manual verification by administration?",
        description2:
          "Once sent for verification, you will not be able to make changes to the order.",
        description3: {
          left: "Until the manual verification of the payment is complete,",
          right: "you will not receive new orders for payment",
        },
      },
    },
    fileInputDrop: {
      placeholder: "Click to upload payment receipt",
      add: "Add",
    },
  },
};

export default orderPageText;
